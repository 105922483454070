
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { BARCODE_ACTION_COMPLETE, BARCODE_SELECTED_TYPE } from '@/store/actions'
import { activateBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { filterEmptyActivateData, toSnakeCase } from '@/utils/helpers'
import {
  IActivateRequestBody,
  IActivationData,
  IAddress,
  IBarcodeFormMultiDataTab,
  IBarcodeFormSingleDataTab,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { ProvideReactive, Watch } from 'vue-property-decorator'

@Options({
  components: {
    BarcodeLabel,
    name: 'BarcodeLabel',
  },
  methods: { toSnakeCase },
})
export default class ActivateView extends Vue {
  @ProvideReactive() isShowArrow = true
  loading = false
  type = ''
  trackpoint = ''
  origin?: IAddress | null = null
  destination?: IAddress | null = null

  currentTab = 0
  steps = ['origin', 'destination', 'packageInfo']

  get barcodeId() {
    return this.$store.state.barcode.barcode?.id
  }

  get showStepper() {
    return this.$route.name.includes('shipping')
  }

  onChangeType(params: IBarcodeFormSingleDataTab) {
    this.type = params.data
    this.trackpoint = ''
    this.origin = null
    this.destination = null
    this.$store.commit(BARCODE_SELECTED_TYPE, this.type)
    this.gotoStep(params.step)
  }

  onChangeTrackpoint(params: IBarcodeFormSingleDataTab) {
    this.trackpoint = params.data
    this.gotoStep(params.step)
  }

  onChangeSource(params: IBarcodeFormMultiDataTab) {
    this.origin = params.origin
    this.gotoStep(params.step)
  }

  onChangeDestination(params: IBarcodeFormMultiDataTab) {
    this.destination = params.destination
    this.gotoStep(params.step)
  }

  gotoStep(gotoStep: number) {
    switch (gotoStep) {
      case 0:
        this.$router.push({ name: 'activate-type' })
        break
      case 1:
        this.$router.push({ name: 'activate-trackpoint' })
        break
      case 2:
        this.$router.push({ name: 'shipping-origin' })
        break
      case 3:
        this.$router.push({ name: 'shipping-destination' })
        break
      case 4:
        this.$router.push({ name: 'shipping-packageInfo' })
        this.isShowArrow = false
        break
    }
  }

  onGotoStep(gotoStep: number) {
    switch (gotoStep) {
      case 0:
        this.$router.replace({ name: 'activate-type' })
        break
      case 1:
        this.$router.replace({ name: 'activate-trackpoint' })
        break
      case 2:
        this.$router.replace({ name: 'shipping-origin' })
        break
      case 3:
        this.$router.replace({ name: 'shipping-destination' })
        break
      case 4:
        this.$router.replace({ name: 'shipping-packageInfo' })
        this.isShowArrow = false
        break
    }
  }

  onChangeDimension(activationData: IActivationData) {
    const { barcodeId, type, origin, destination } = this
    const requestSchema = {
      barcodeId,
      barcodeType: type,
      ...(this.trackpoint && { startTrackPointKeyOverride: this.trackpoint }),
      activationData: {
        ...activationData,
        ...(origin && { origin }),
        ...(destination && { destination }),
      },
    } as IActivateRequestBody

    const filteredObj = filterEmptyActivateData(requestSchema.activationData)
    const requestBody = {
      ...requestSchema,
      activationData: filteredObj,
    } as IActivateRequestBody

    // Send API
    this.activate(requestBody)
  }

  async activate(data: IActivateRequestBody) {
    try {
      this.loading = true
      await activateBarcode(data)
      this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'activated')
      this.$router.push({
        name: 'detail',
        params: {
          barcodeId: this.barcodeId,
        },
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  created() {
    const key = this.$route.name.split('-')
    this.currentTab = this.steps.indexOf(key[1])
  }

  @Watch('$route')
  onRouteUpdate(to: { name: string }) {
    const key = to.name.split('-')
    this.currentTab = this.steps.indexOf(key[1])
  }
}
