
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import AddressBookNarrowDown from '@/components/AddressBookNarrowDown.vue'
import ShippingInfo from '@/components/ShippingInfo.vue'
import IconArrow from '@/components/svg/IconArrow.vue'
import { IAddress, IBarcodeDefinitionType, IBarcodeFormMultiDataTab, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { DEFAULT_ADDRESS } from '@/utils/constants'
import { SET_BARCODE_FORM_STEP } from '@/store/actions'

@Options({
  components: {
    ShippingInfo,
    AddressBookNarrowDown,
    IconArrow,
  },
  emits: ['update:destination', 'update:onGotoStep'],
  name: 'ShippingDestination',
})
export default class ShippingDestination extends Vue {
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: Object }) readonly destinationAddress?: IAddress | undefined = undefined

  // New address mode
  selectedMode = 0
  inputModes: string[] = []
  step = 3
  selectedAddressIndex = ''
  selectedDestinationAddress: IAddress = { ...DEFAULT_ADDRESS }

  get project(): IProject {
    return this.$store.state.project?.details
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes[this.barcodeType]
  }

  get isAvailable() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isAvailable
  }

  get isMandatory() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isMandatory
  }

  get profileAddressHistory() {
    return this.$store.state.profile.addressHistory
  }

  get profileAddressBook() {
    return this.$store.state.profile.addressBook
  }

  get addressOptions() {
    if (this.selectedMode === 1) {
      this.onSelectAddressCompleted(this.profileAddressHistory[this.selectedAddressIndex ?? 0])
      return this.profileAddressHistory
    }

    if (this.selectedMode === 2) {
      this.onSelectAddressCompleted(this.profileAddressBook[this.selectedAddressIndex ?? 0])
      return this.profileAddressBook || []
    }
  }

  get isAddressHistoryMode() {
    return this.selectedMode === 1
  }

  get isAddressBookMode() {
    return this.selectedMode === 2
  }

  // disable next step in edit mode
  get hasNextStep() {
    return this.$route.name !== 'edit'
  }

  onAddressBookCbChange() {
    this.onSelectAddressCompleted(this.addressOptions[this.selectedAddressIndex])
  }

  shouldDisableMode(index: number) {
    return this.selectedMode === index
  }

  onSelectAddressCompleted(newVal: IAddress) {
    if (!this.selectedDestinationAddress) {
      return
    }

    this.selectedDestinationAddress = {
      ...this.selectedDestinationAddress,
      ...newVal,
    }
  }

  @Emit('update:destination')
  onSubmitForm(data: IAddress): IBarcodeFormMultiDataTab {
    return {
      destination: data,
      step: this.step + 1,
    }
  }

  created() {
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    // skip this step if destination is unavailable from project settings
    if (!this.isAvailable) {
      if (this.$store.state.barcode.trackedStep > this.step) {
        this.$emit('update:onGotoStep', this.step - 1)
      } else {
        this.$emit('update:onGotoStep', this.step + 1)
      }
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)

    if (this.destinationAddress) {
      this.selectedDestinationAddress = this.destinationAddress
    }
    this.inputModes = ['Direct Input', 'Select from Address History', 'Select from Address Book']
  }
}
