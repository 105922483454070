<template>
  <div>
    <label> {{ $t('destination_prefecture') }} </label>
    <el-select class="d-block" v-model="selectedPrefecture" :placeholder="$t('destination_select_prefecture')">
      <el-option :key="-1" value=""></el-option>
      <el-option v-for="(item, index) in distinctPrefecture" :key="index" :label="item" :value="item">
        {{ item }}
      </el-option>
    </el-select>
  </div>
  <br />
  <div>
    <label> {{ $t('destination_address1') }} </label>
    <el-select class="d-block" v-model="selectedAddress" :placeholder="$t('destination_select_address_1')">
      <el-option :key="-1" value=""></el-option>
      <el-option v-for="(item, index) in distinctAddress" :key="index" :label="item" :value="item">
        {{ item }}
      </el-option>
    </el-select>
  </div>
  <br />
  <div>
    <label> {{ $t('destination_company_name') }} </label>
    <el-select
      class="d-block"
      v-model="selectedCompanyName"
      :placeholder="$t('destination_select_company_name')"
      @change="onSelectCompleted"
    >
      <el-option :key="-1" value=""></el-option>
      <el-option v-for="(item, index) in companyNames" :key="index" :label="item.companyName" :value="index">
        {{ item.companyName }}
      </el-option>
    </el-select>
  </div>
  <br />
  <hr />
</template>
<script lang="ts">
import Component, { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IAddress } from 'smartbarcode-web-core/src/utils/types/index'
@Options({
  components: {
    Component,
    Prop,
  },
  name: 'AddressBookNarrowDown',
  emits: ['update:onSelectAddressCompleted'],
})
export default class AddressBookNarrowDown extends Vue {
  @Prop({ type: Object }) readonly addresses!: Array<IAddress>
  @Prop({ type: Object }) readonly address!: IAddress
  selectedPrefecture = ''
  selectedAddress = ''
  selectedCompanyName = ''
  get distinctPrefecture(): Array<string> {
    return [...new Set(this.addresses.map((addr) => addr.prefecture))]
  }

  get distinctAddress(): Array<string> {
    return [
      ...new Set(
        this.addresses.filter((addr) => this.selectedPrefecture === addr.prefecture).map((addr) => addr.address1)
      ),
    ]
  }

  get companyNames(): Array<IAddress> {
    return [
      ...new Set(
        this.addresses.filter(
          (addr) => this.selectedPrefecture === addr.prefecture && this.selectedAddress === addr.address1
        )
      ),
    ]
  }

  @Watch('selectedPrefecture')
  onSelectedPrefectureChanged() {
    this.selectedAddress = ''
    this.selectedCompanyName = ''
  }

  @Watch('selectedAddress')
  onSelectedAddressChanged() {
    this.selectedCompanyName = ''
  }

  onSelectCompleted(index: number): void {
    this.$emit('update:onSelectAddressCompleted', {
      ...this.companyNames[index],
      prefecture: this.selectedPrefecture,
      address1: this.selectedAddress,
    })
  }
}
</script>
