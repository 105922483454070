<template>
  <div class="activate-type-view">
    <div class="step-label">{{ $t('Select barcode type') }}</div>
    <p>{{ $t('Start activate barcode by selecting type first') }}</p>
    <div class="button-container">
      <el-button
        v-for="(barcodeTypeInfo, nodeType) in availableBarcodeTypes"
        :class="{ 'full-button': true }"
        v-bind:id="'ActiveType_' + nodeType"
        :key="barcodeTypeInfo.name"
        :style="barcodeButtonStyles(barcodeTypeInfo)"
        @click="onSelectType(String(nodeType))"
      >
        <div class="action-button" :style="barcodeButtonTextStyles(barcodeTypeInfo)">
          <component :is="barcodeIconComponents[barcodeTypeInfo.icon]" :style="barcodeIconStyles(barcodeTypeInfo)" />

          <div class="action-button__name">
            {{ barcodeTypeInfo.name }}
          </div>
        </div>
      </el-button>
    </div>
  </div>
</template>
<script lang="ts">
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import IconGroupOfCode from '@/components/svg/IconGroupOfCode.vue'
import IconMiniCode from '@/components/svg/IconMiniCode.vue'
import IconQRcode from '@/components/svg/IconQRcode.vue'
import { SET_BARCODE_FORM_STEP } from '@/store/actions'
import { IBarcodeFormSingleDataTab } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Emit } from 'vue-property-decorator'

@Options({
  components: {
    IconGroupOfCode,
    IconMiniCode,
    IconQRcode,
  },
  emits: ['update:barcodeType'],
  name: 'ActivateType',
})
export default class ActivateType extends mixins(BarcodeTypesMixin) {
  step = 0

  created() {
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)
  }

  @Emit('update:barcodeType')
  onSelectType(type: string) {
    return {
      data: type,
      step: this.step + 1,
    } as IBarcodeFormSingleDataTab
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

div.activate-type-view {
  width: 240px;
  text-align: center;

  .button-container {
    margin-top: 33px;

    .el-button--default {
      padding: 18px 0px;
    }
  }
}
</style>
