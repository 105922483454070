
import ShippingInfo from '@/components/ShippingInfo.vue'
import { SET_BARCODE_FORM_STEP } from '@/store/actions'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IAddress, IBarcodeFormMultiDataTab, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, InjectReactive, Prop } from 'vue-property-decorator'

@Options({
  components: {
    ShippingInfo,
  },
  emits: ['update:origin', 'update:onGotoStep'],
  name: 'ShippingSource',
})
export default class ShippingSource extends Vue {
  step = 2
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: Object }) readonly originAddress?: IAddress
  @InjectReactive() isShowArrow!: boolean

  created() {
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    // skip this step if origin is unavailable from project settings
    if (!this.isAvailable) {
      if (this.$store.state.barcode.trackedStep > this.step) {
        this.$emit('update:onGotoStep', this.step - 1)
      } else {
        this.$emit('update:onGotoStep', this.step + 1)
      }
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)
  }

  get project(): IProject {
    return this.$store.state.project?.details
  }

  get isAvailable() {
    return this.project.barcodeTypes[this.barcodeType]?.activationFields?.origin?.isAvailable
  }

  get isMandatory() {
    return this.project.barcodeTypes[this.barcodeType]?.activationFields?.origin?.isMandatory
  }

  // if originAddress is defined, it is in edit mode
  get isEditMode() {
    return this.$route.name === 'edit'
  }

  get address() {
    const store = this.$store.state.profile
    const { user, organization } = store

    if (this.isEditMode || this.originAddress) {
      return { ...this.originAddress }
    }

    if (user?.userType === EUserType.CLIENT) {
      return { ...organization.address }
    }
    return null
  }

  @Emit('update:origin')
  onSubmitForm(data: IAddress): IBarcodeFormMultiDataTab {
    return {
      origin: data,
      step: this.step + 1,
    }
  }
}
