import { IValidationRule } from 'smartbarcode-web-core/src/utils/types/index'
import i18n from '@/i18n/i18n'
import { EValidationType } from 'smartbarcode-web-core/src/utils/enums'

export const serverMappingValidate = (rules: IValidationRule, field: string, value: string | number) => {
  const trans = i18n.global.t
  const stringVal = value?.toString() || ''

  if (rules.allowNullOrEmpty && stringVal === '') {
    return ''
  }

  if (typeof rules.allowNullOrEmpty !== 'undefined' && !rules.allowNullOrEmpty && stringVal.trim() === '') {
    return trans('Please enter', { field })
  }

  const validationType = rules.validationType
  if (typeof validationType !== 'undefined' && validationType !== EValidationType.none) {
    switch (validationType) {
      case EValidationType.digitOnly:
        if (!/^[0-9]+$/.test(stringVal)) {
          return trans('Digits only')
        }
        break
      case EValidationType.digitDashOnly:
        if (!/^[0-9-]+$/.test(stringVal)) {
          return trans('Digits dash only')
        }
        break
      case EValidationType.digitUnderscoreOnly:
        if (!/^[0-9_]+$/.test(stringVal)) {
          return trans('Digits underscore only')
        }
        break
      case EValidationType.letterOnly:
        if (!/^[a-zA-Z]+$/.test(stringVal)) {
          return trans('Letters only')
        }
        break
      case EValidationType.letterDashOnly:
        if (!/^[a-zA-Z-]+$/.test(stringVal)) {
          return trans('Letters dash only')
        }
        break
      case EValidationType.letterUnderscoreOnly:
        if (!/^[a-zA-Z_]+$/.test(stringVal)) {
          return trans('Letters underscore only')
        }
        break
      case EValidationType.letterDigitOnly:
        if (!/^[a-zA-Z0-9]+$/.test(stringVal)) {
          return trans('Letters digits only')
        }
        break
      case EValidationType.letterDigitDashOnly:
        if (!/^[a-zA-Z0-9-]+$/.test(stringVal)) {
          return trans('Letters digits dash only')
        }
        break
      case EValidationType.letterDigitUnderscoreOnly:
        if (!/^[a-zA-Z0-9_]+$/.test(stringVal)) {
          return trans('Letters digits underscore only')
        }
        break
      default:
        break
    }
  }

  // minLength
  const minLength = rules.minLength
  if (minLength && minLength > 0 && stringVal.length < minLength) {
    return trans('MinLen limit', {
      len: minLength,
    })
  }

  // maxLength
  const maxLength = rules.maxLength
  if (maxLength && maxLength > 0 && stringVal.length > maxLength) {
    return trans('MaxLen limit', {
      len: maxLength,
    })
  }

  return ''
}
