
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { countryList } from '@/utils/constants'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'

type TCountryOption = {
  key: string
  label: string
}

type TCountryGroup = {
  label: string
  options: Array<TCountryOption>
}

@Options({
  name: 'InputSelectCountry',
})
export default class InputSelectCountry extends mixins(BarcodeTypesMixin) {
  @Prop({ type: String }) readonly fieldName?: string = ''
  countryOptions: TCountryGroup[] = []

  get barcodeType() {
    return this.$store.state?.barcode?.currentSelectedBarcodeType || this.$store.state?.barcode?.barcode?.barcodeType
  }

  get countriesList() {
    return this.getBarcodeTypeInfoByType(this.barcodeType)?.countries || []
  }

  created() {
    const getOptionLabels = (o: string[]) =>
      o.map((countryKey) => ({
        key: countryKey,
        label: this.$t(`countryList.${countryKey}`),
      }))

    if (this.countriesList?.length) {
      this.countryOptions = [
        {
          label: this.$t('all countries'),
          options: getOptionLabels(this.countriesList),
        },
      ]
    } else {
      // Take the first three items as "frequently used"
      const list = [...countryList]
      const frequentlyUsed = list.splice(0, 3)

      this.countryOptions = [
        {
          label: this.$t('frequently used'),
          options: getOptionLabels(frequentlyUsed),
        },
        {
          label: this.$t('all countries'),
          options: getOptionLabels(list),
        },
      ]
    }
  }
}
