<template>
  <div class="activate-view view" v-loading.fullscreen="loading">
    <BarcodeLabel :label="barcodeId" :type="type" />
    <div class="stepper" v-if="showStepper">
      <div class="step" :class="{ active: currentTab === index }" v-for="(item, index) in steps" :key="index">
        {{ $t(toSnakeCase(item)) }}
      </div>
    </div>
    <router-view
      :barcodeType="type"
      :trackpointData="trackpoint"
      :originAddress="origin"
      :destinationAddress="destination"
      @update:barcodeType="onChangeType"
      @update:trackpoint="onChangeTrackpoint"
      @update:origin="onChangeSource"
      @update:destination="onChangeDestination"
      @update:dimension="onChangeDimension"
      @update:onGotoStep="onGotoStep"
    >
    </router-view>
  </div>
</template>

<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { BARCODE_ACTION_COMPLETE, BARCODE_SELECTED_TYPE } from '@/store/actions'
import { activateBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { filterEmptyActivateData, toSnakeCase } from '@/utils/helpers'
import {
  IActivateRequestBody,
  IActivationData,
  IAddress,
  IBarcodeFormMultiDataTab,
  IBarcodeFormSingleDataTab,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { ProvideReactive, Watch } from 'vue-property-decorator'

@Options({
  components: {
    BarcodeLabel,
    name: 'BarcodeLabel',
  },
  methods: { toSnakeCase },
})
export default class ActivateView extends Vue {
  @ProvideReactive() isShowArrow = true
  loading = false
  type = ''
  trackpoint = ''
  origin?: IAddress | null = null
  destination?: IAddress | null = null

  currentTab = 0
  steps = ['origin', 'destination', 'packageInfo']

  get barcodeId() {
    return this.$store.state.barcode.barcode?.id
  }

  get showStepper() {
    return this.$route.name.includes('shipping')
  }

  onChangeType(params: IBarcodeFormSingleDataTab) {
    this.type = params.data
    this.trackpoint = ''
    this.origin = null
    this.destination = null
    this.$store.commit(BARCODE_SELECTED_TYPE, this.type)
    this.gotoStep(params.step)
  }

  onChangeTrackpoint(params: IBarcodeFormSingleDataTab) {
    this.trackpoint = params.data
    this.gotoStep(params.step)
  }

  onChangeSource(params: IBarcodeFormMultiDataTab) {
    this.origin = params.origin
    this.gotoStep(params.step)
  }

  onChangeDestination(params: IBarcodeFormMultiDataTab) {
    this.destination = params.destination
    this.gotoStep(params.step)
  }

  gotoStep(gotoStep: number) {
    switch (gotoStep) {
      case 0:
        this.$router.push({ name: 'activate-type' })
        break
      case 1:
        this.$router.push({ name: 'activate-trackpoint' })
        break
      case 2:
        this.$router.push({ name: 'shipping-origin' })
        break
      case 3:
        this.$router.push({ name: 'shipping-destination' })
        break
      case 4:
        this.$router.push({ name: 'shipping-packageInfo' })
        this.isShowArrow = false
        break
    }
  }

  onGotoStep(gotoStep: number) {
    switch (gotoStep) {
      case 0:
        this.$router.replace({ name: 'activate-type' })
        break
      case 1:
        this.$router.replace({ name: 'activate-trackpoint' })
        break
      case 2:
        this.$router.replace({ name: 'shipping-origin' })
        break
      case 3:
        this.$router.replace({ name: 'shipping-destination' })
        break
      case 4:
        this.$router.replace({ name: 'shipping-packageInfo' })
        this.isShowArrow = false
        break
    }
  }

  onChangeDimension(activationData: IActivationData) {
    const { barcodeId, type, origin, destination } = this
    const requestSchema = {
      barcodeId,
      barcodeType: type,
      ...(this.trackpoint && { startTrackPointKeyOverride: this.trackpoint }),
      activationData: {
        ...activationData,
        ...(origin && { origin }),
        ...(destination && { destination }),
      },
    } as IActivateRequestBody

    const filteredObj = filterEmptyActivateData(requestSchema.activationData)
    const requestBody = {
      ...requestSchema,
      activationData: filteredObj,
    } as IActivateRequestBody

    // Send API
    this.activate(requestBody)
  }

  async activate(data: IActivateRequestBody) {
    try {
      this.loading = true
      await activateBarcode(data)
      this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'activated')
      this.$router.push({
        name: 'detail',
        params: {
          barcodeId: this.barcodeId,
        },
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  created() {
    const key = this.$route.name.split('-')
    this.currentTab = this.steps.indexOf(key[1])
  }

  @Watch('$route')
  onRouteUpdate(to: { name: string }) {
    const key = to.name.split('-')
    this.currentTab = this.steps.indexOf(key[1])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.activate-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
}

.stepper {
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 38px;
  overflow: hidden;

  .step {
    padding: 5px 0 5px 16px;
    width: 33.33%;
    background: $dark-grey;
    color: $pure-white;
    text-align: center;
    position: relative;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 0;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-right: 8px;
    }

    &:not(:last-child):after {
      border-top: 15px inset transparent;
      border-bottom: 15px inset transparent;
      border-left: 15px solid $dark-grey;
      right: -11px;
      content: '';
      position: absolute;
      top: -2px;
      width: 0;
      height: 0;
      z-index: 2;
    }

    &:not(:first-child):before {
      border-top: 15px inset transparent;
      border-bottom: 15px inset transparent;
      border-left: 15px solid $pure-white;
      position: absolute;
      content: '';
      top: -2px;
      left: -2px;
      width: 0;
      height: 0;
    }

    &.active {
      background: $main;

      &:not(:last-child):after {
        border-left-color: $main;
      }
    }
  }
}
</style>
