<template>
  <div class="shipping-destination-view" id="ShippingDestination">
    <el-button
      type="primary"
      v-for="(type, index) in inputModes"
      :key="type"
      @click="selectedMode = index"
      class="mode-selector"
      :class="{ active: selectedMode === index }"
      :disabled="shouldDisableMode(index)"
      >{{ $t(type) }}</el-button
    >

    <div class="destination-container" v-if="isAddressHistoryMode">
      <label for="destination">{{ $t('destination') }}</label>
      <el-select v-model="selectedAddressIndex" @change="onAddressBookCbChange">
        <el-option v-for="(item, index) in addressOptions" :key="index" :label="item.companyName" :value="index">
          <span class="main-label">{{ item.companyName }}</span>
          <span class="sub-label">{{ item.address1 }}</span>
        </el-option>
      </el-select>
    </div>
    <div v-else-if="isAddressBookMode">
      <AddressBookNarrowDown
        :address="selectedDestinationAddress"
        :addresses="addressOptions"
        @update:onSelectAddressCompleted="onSelectAddressCompleted"
      />
    </div>
    <hr v-else />

    <!-- use unavailable for this -->
    <ShippingInfo
      :barcodeType="barcodeType"
      :address="selectedDestinationAddress"
      :hasNextStep="hasNextStep"
      :isMandatory="isMandatory"
      @submit="onSubmitForm($event)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import AddressBookNarrowDown from '@/components/AddressBookNarrowDown.vue'
import ShippingInfo from '@/components/ShippingInfo.vue'
import IconArrow from '@/components/svg/IconArrow.vue'
import { IAddress, IBarcodeDefinitionType, IBarcodeFormMultiDataTab, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { DEFAULT_ADDRESS } from '@/utils/constants'
import { SET_BARCODE_FORM_STEP } from '@/store/actions'

@Options({
  components: {
    ShippingInfo,
    AddressBookNarrowDown,
    IconArrow,
  },
  emits: ['update:destination', 'update:onGotoStep'],
  name: 'ShippingDestination',
})
export default class ShippingDestination extends Vue {
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: Object }) readonly destinationAddress?: IAddress | undefined = undefined

  // New address mode
  selectedMode = 0
  inputModes: string[] = []
  step = 3
  selectedAddressIndex = ''
  selectedDestinationAddress: IAddress = { ...DEFAULT_ADDRESS }

  get project(): IProject {
    return this.$store.state.project?.details
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes[this.barcodeType]
  }

  get isAvailable() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isAvailable
  }

  get isMandatory() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isMandatory
  }

  get profileAddressHistory() {
    return this.$store.state.profile.addressHistory
  }

  get profileAddressBook() {
    return this.$store.state.profile.addressBook
  }

  get addressOptions() {
    if (this.selectedMode === 1) {
      this.onSelectAddressCompleted(this.profileAddressHistory[this.selectedAddressIndex ?? 0])
      return this.profileAddressHistory
    }

    if (this.selectedMode === 2) {
      this.onSelectAddressCompleted(this.profileAddressBook[this.selectedAddressIndex ?? 0])
      return this.profileAddressBook || []
    }
  }

  get isAddressHistoryMode() {
    return this.selectedMode === 1
  }

  get isAddressBookMode() {
    return this.selectedMode === 2
  }

  // disable next step in edit mode
  get hasNextStep() {
    return this.$route.name !== 'edit'
  }

  onAddressBookCbChange() {
    this.onSelectAddressCompleted(this.addressOptions[this.selectedAddressIndex])
  }

  shouldDisableMode(index: number) {
    return this.selectedMode === index
  }

  onSelectAddressCompleted(newVal: IAddress) {
    if (!this.selectedDestinationAddress) {
      return
    }

    this.selectedDestinationAddress = {
      ...this.selectedDestinationAddress,
      ...newVal,
    }
  }

  @Emit('update:destination')
  onSubmitForm(data: IAddress): IBarcodeFormMultiDataTab {
    return {
      destination: data,
      step: this.step + 1,
    }
  }

  created() {
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    // skip this step if destination is unavailable from project settings
    if (!this.isAvailable) {
      if (this.$store.state.barcode.trackedStep > this.step) {
        this.$emit('update:onGotoStep', this.step - 1)
      } else {
        this.$emit('update:onGotoStep', this.step + 1)
      }
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)

    if (this.destinationAddress) {
      this.selectedDestinationAddress = this.destinationAddress
    }
    this.inputModes = ['Direct Input', 'Select from Address History', 'Select from Address Book']
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.mode-selector {
  width: 100%;
  margin-bottom: 16px;
}

.shipping-destination-view {
  .el-button + .el-button {
    margin-left: 0;
  }
}

.destination-container {
  text-align: initial;

  .el-select {
    display: block;
  }

  .el-button {
    margin: 0 auto;
    margin-top: 16px;
    display: block;
  }
}

.el-select-dropdown__item {
  overflow: unset;
  line-height: unset;
  padding: 8px 20px;
  height: unset;
}

.sub-label {
  font-size: 12px;
  display: block;
}
</style>
