
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { updateBarcode } from '@/utils/api'
import { ENTRY_VIEW, FORCE_EXIT_ROUTE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { addEmptyActivationData, filterEmptyActivateData, toSnakeCase } from '@/utils/helpers'
import { IActivationData, IAddress, IBarcodeDefinitionType, IDimension, TError } from 'smartbarcode-web-core/src/utils/types/index'
import PackageInfo from '@/views/PackageInfo.vue'
import ShippingDestination from '@/views/ShippingDestination.vue'
import ShippingSource from '@/views/ShippingSource.vue'
import { Options, Vue } from 'vue-class-component'
import { Prop, ProvideReactive } from 'vue-property-decorator'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

@Options({
  components: {
    BarcodeLabel,
  },
  methods: { toSnakeCase },
  name: 'EditView',
})
export default class EditView extends Vue {
  @Prop({ type: String }) readonly view!: string
  @ProvideReactive() isShowArrow = false
  currentEntryView = ''
  currentEntryRequest = {}
  isAllowToLeave = false
  showStepper = false
  steps = ['origin', 'destination', 'packageInfo']

  editView = {
    origin: ShippingSource,
    destination: ShippingDestination,
    packageInfo: PackageInfo,
  }

  isActiveStep(item: string) {
    return item === this.currentView
  }

  get isDimensionAvailable() {
    return (
      this.barcodeTypeInfo?.activationFields?.origin?.isAvailable ||
      this.barcodeTypeInfo?.activationFields?.destination?.isAvailable ||
      this.barcodeTypeInfo?.activationFields?.dimension?.isAvailable ||
      this.barcodeTypeInfo?.activationFields?.externalId?.isAvailable ||
      this.barcodeTypeInfo?.activationFields?.trackingNumber?.isAvailable
    )
  }

  get project() {
    return this.$store.state.project.details
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes[this.barcode?.barcodeType] || {}
  }

  get destinationAvailable() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isAvailable
  }

  get originAvailable() {
    return this.barcodeTypeInfo?.activationFields?.origin?.isAvailable
  }

  get currentView() {
    if (this.view) {
      return this.view
    } else {
      return this.currentEntryView
    }
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  get origin(): IAddress {
    return this.$store.getters.origin
  }

  get destination(): IAddress {
    return this.$store.getters.destination
  }

  get dimension(): IDimension {
    return this.$store.getters.dimension
  }

  get trackingNumber() {
    return this.$store.getters.trackingNumber
  }

  get externalId() {
    return this.$store.getters.externalId
  }

  get productId() {
    return this.barcode?.activationData?.productId
  }

  get activationData() {
    return {
      origin: this.origin,
      destination: this.destination,
      trackingNumber: this.trackingNumber,
      externalId: this.externalId,
      dimension: this.dimension,
      productId: this.productId,
    }
  }

  // Prevent backing while enter entry flow
  beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (
      this.currentEntryView === ENTRY_VIEW.ORIGIN ||
      this.isAllowToLeave ||
      FORCE_EXIT_ROUTE.includes(to.name?.toString() || '')
    ) {
      next(true)
      return
    }
    switch (this.currentEntryView) {
      case ENTRY_VIEW.ORIGIN:
        this.isShowArrow = true
        break
      case ENTRY_VIEW.DESTINATION:
        if (!this.originAvailable) {
          next(true)
        }
        this.currentEntryView = ENTRY_VIEW.ORIGIN
        this.isShowArrow = true
        next(false)
        break
      case ENTRY_VIEW.PACKAGE_INFO:
        this.currentEntryView = ENTRY_VIEW.DESTINATION
        this.isShowArrow = true
        next(false)
        break
      default:
        next(false)
        break
    }
  }

  // Skip to next flow automatically
  skipToNextStep() {
    switch (this.currentEntryView) {
      case ENTRY_VIEW.ORIGIN:
        this.currentEntryView = ENTRY_VIEW.DESTINATION
        break
      case ENTRY_VIEW.DESTINATION:
        this.currentEntryView = ENTRY_VIEW.PACKAGE_INFO
        break
      case ENTRY_VIEW.PACKAGE_INFO:
        break
      default:
        break
    }
  }

  async update(activationData: IActivationData) {
    try {
      // When passing specific view
      if (this.view) {
        const activationRequestData = {
          ...this.activationData,
          ...activationData,
        }
        const requestObj = addEmptyActivationData(activationRequestData, this.barcode?.activationData)
        // ---------------------
        await updateBarcode({
          barcodeId: this.barcode.id,
          activationData: requestObj as IActivationData,
        })
        this.$router.replace({
          name: 'detail',
          params: {
            project: this.$store.getters.projectParam,
            barcodeId: this.barcode.id,
          },
        })
      } else {
        // Otherwise we use entry flow logic to next step
        this.currentEntryRequest = {
          ...this.currentEntryRequest,
          ...activationData,
        }
        if (activationData.origin) {
          this.currentEntryView = ENTRY_VIEW.DESTINATION
          return
        }
        if (activationData.destination) {
          this.isShowArrow = false
          this.currentEntryView = ENTRY_VIEW.PACKAGE_INFO
          return
        }
        const filteredObj = filterEmptyActivateData(this.currentEntryRequest)
        await updateBarcode({
          barcodeId: this.barcode.id,
          activationData: filteredObj as IActivationData,
        })
        this.isAllowToLeave = true
        this.$router.replace({
          name: 'detail',
          params: {
            project: this.$store.getters.projectParam,
            barcodeId: this.barcode.id,
          },
        })
      }
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  mounted() {
    // When passing specific view
    if (this.view) {
      this.isShowArrow = false
      this.isAllowToLeave = true
    } else {
      // Otherwise we use entry flow logic to next step
      this.isShowArrow = true
      this.showStepper = true
      if (this.originAvailable) {
        this.currentEntryView = ENTRY_VIEW.ORIGIN
      } else if (this.destinationAvailable) {
        this.currentEntryView = ENTRY_VIEW.DESTINATION
      } else if (this.isDimensionAvailable) {
        this.currentEntryView = ENTRY_VIEW.PACKAGE_INFO
        this.isAllowToLeave = true
      } else {
        this.isAllowToLeave = true
        this.$router.replace({
          name: 'detail',
          params: { barcodeId: this.$route.params.id },
        })
      }
    }
    if (!this.currentView && this.$route.params.id) {
      this.$router.replace({
        name: 'detail',
        params: { barcodeId: this.$route.params.id },
      })
    }
  }
}
