<template>
  <div class="activate-trackpoint" id="ActivateTrackpoint">
    <div class="step-label">{{ $t('Select the start tracking point') }}</div>
    <p>{{ $t('Start activate barcode by selecting type first') }}</p>
    <div class="track-points" v-if="project">
      <el-button
        v-for="tp in trackPoints"
        :id="'ActivateTrackpoint_trackpoint_' + tp.key"
        class="full-button"
        :class="isActive(tp.key)"
        :key="tp.key"
        :disabled="!overridableStartTrackingPoints.includes(tp.key)"
        @click="onSelectTrackpoint(tp.key)"
      >
        {{ tp.name }}
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { SET_BARCODE_FORM_STEP } from '@/store/actions'
import { IBarcodeFormSingleDataTab, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:trackpoint', 'update:onGotoStep'],
  name: 'ActivatTrackPointView',
})
export default class ActivateTrackPointView extends Vue {
  step = 1
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: String }) readonly trackpointData = ''

  created() {
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    // skip this step only if overridableStartTrackingPoints array is empty
    if (!this.overridableStartTrackingPoints?.length) {
      const step = this.step + (this.$store.state.barcode.trackedStep < this.step ? 1 : -1)
      this.$emit('update:onGotoStep', step)
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)
  }

  get barcodeTypeInfo() {
    return this.$store.state.project?.details?.barcodeTypes[this.barcodeType]
  }

  get project() {
    return this.$store.state.project.details
  }

  get trackPoints() {
    if (!this.project) return null

    return Object.entries(this.project.trackPoints as { [key: string]: ITrackpoint })
      .map(([key, value]) => ({ key, ...value }))
      .filter((t) => !t.isEnd)
      .sort((a, b) => (a.order && b.order ? a.order - b.order : Number(a.key) - Number(b.key)))
  }

  get overridableStartTrackingPoints() {
    return this.barcodeTypeInfo?.overridableStartTrackPoints
  }

  isActive(key: string): string {
    return this.trackpointData === key ? 'selected' : ''
  }

  @Emit('update:trackpoint')
  onSelectTrackpoint(key: string) {
    return {
      data: key,
      step: this.step + 1,
    } as IBarcodeFormSingleDataTab
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.activate-trackpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

p {
  margin-bottom: 24px;
}

.track-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  button {
    &.selected {
      border-color: $main;
    }
  }
}
</style>
