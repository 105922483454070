
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import IconGroupOfCode from '@/components/svg/IconGroupOfCode.vue'
import IconMiniCode from '@/components/svg/IconMiniCode.vue'
import IconQRcode from '@/components/svg/IconQRcode.vue'
import { SET_BARCODE_FORM_STEP } from '@/store/actions'
import { IBarcodeFormSingleDataTab } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Emit } from 'vue-property-decorator'

@Options({
  components: {
    IconGroupOfCode,
    IconMiniCode,
    IconQRcode,
  },
  emits: ['update:barcodeType'],
  name: 'ActivateType',
})
export default class ActivateType extends mixins(BarcodeTypesMixin) {
  step = 0

  created() {
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)
  }

  @Emit('update:barcodeType')
  onSelectType(type: string) {
    return {
      data: type,
      step: this.step + 1,
    } as IBarcodeFormSingleDataTab
  }
}
