
import { SET_BARCODE_FORM_STEP } from '@/store/actions'
import { IBarcodeFormSingleDataTab, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:trackpoint', 'update:onGotoStep'],
  name: 'ActivatTrackPointView',
})
export default class ActivateTrackPointView extends Vue {
  step = 1
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: String }) readonly trackpointData = ''

  created() {
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    // skip this step only if overridableStartTrackingPoints array is empty
    if (!this.overridableStartTrackingPoints?.length) {
      const step = this.step + (this.$store.state.barcode.trackedStep < this.step ? 1 : -1)
      this.$emit('update:onGotoStep', step)
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)
  }

  get barcodeTypeInfo() {
    return this.$store.state.project?.details?.barcodeTypes[this.barcodeType]
  }

  get project() {
    return this.$store.state.project.details
  }

  get trackPoints() {
    if (!this.project) return null

    return Object.entries(this.project.trackPoints as { [key: string]: ITrackpoint })
      .map(([key, value]) => ({ key, ...value }))
      .filter((t) => !t.isEnd)
      .sort((a, b) => (a.order && b.order ? a.order - b.order : Number(a.key) - Number(b.key)))
  }

  get overridableStartTrackingPoints() {
    return this.barcodeTypeInfo?.overridableStartTrackPoints
  }

  isActive(key: string): string {
    return this.trackpointData === key ? 'selected' : ''
  }

  @Emit('update:trackpoint')
  onSelectTrackpoint(key: string) {
    return {
      data: key,
      step: this.step + 1,
    } as IBarcodeFormSingleDataTab
  }
}
